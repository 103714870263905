import { html, dispatch, property } from "hybrids";

/**
 *  doCancel event handling, dispatches cancel event so that events can be attached to oncancel
 * @param {*} host 
 * @param {*} target 
 */
function doCancel(host, target) {
  host.showModal = false;
  dispatch(host, "cancel");
}
/**
 *  doOk event handling, dispatches ok-event so that events can be attached to onok on the element.
 * @param {*} host 
 * @param {*} target 
 */
function doOk(host, target) {
  host.showModal = false;
  dispatch(host, "ok");
}

/**
 * DelteModal shows a modal dialog with ok and cancel buttons.
 * 
 * @param showModal toggles the modal on/off.
 */
const DeleteModal = {
  showModal: false,
  ok: "ok",
  heading: "Delete location",
  cancel: "cancel",
  render: ({ showModal, ok, cancel, heading }) => html`
    <link rel="stylesheet" href="/mystyles.css" />
    <!--  Modal dialog for deleting a location -->
    <div class=" ${showModal == false && "hidden"}">
      <div
        class="fixed top-0 left-0 flex items-center justify-center w-full h-full"
      >
        <div
          class="absolute w-full h-full bg-gray-900 opacity-25 pointer-events modal-overlay"
        ></div>

        <div
          class="z-50 max-w-md px-4 py-3 bg-gray-100 border-2 border-gray-400 rounded-lg shadow-lg text-grey-700"
          role="alert"
        >
          <div class="flex">
            <div class="py-1">
              <svg
                class="w-6 h-6 mr-4 text-black fill-current animate-pulse "
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path
                  d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"
                />
              </svg>
            </div>
            <div>
              <h1 class="text-lg font-bold text-gray-800">${heading}</h1>
              <p class="text-sm text-black">
                <slot></slot>
              </p>
            </div>
          </div>
          <div class="flex justify-end">
            <button
              class="pt-2 pb-2 pl-4 pr-4 m-1 text-white bg-blue-600 rounded shadow hover:bg-blue-800"
              onclick="${doOk}"
            >
              ${ok}
            </button>
            <button
              class="pt-2 pb-2 pl-4 pr-4 m-1 text-white bg-gray-600 rounded shadow 4 hover:bg-gray-800"
              onclick="${doCancel}"
            >
              ${cancel}
            </button>
          </div>
        </div>
      </div>
    </div>
  `,
};

export default DeleteModal;
