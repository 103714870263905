/*
if (module.hot) {
    module.hot.accept();
}
*/
import {define } from 'hybrids'


import AdminApp from './admin/admin-app.js';
import IfAdmin from './admin/if-admin.js'
import RealtimeMap from './map.js'
import {LocationShow, LocationsAdmin,LocationList} from './admin/locations-admin.js'
import DeleteModal from './components/delete-modal.js'

define({IfAdmin,
    AdminApp,
    RealtimeMap,
    LocationsAdmin,
    LocationList,
    LocationShow,
    // Experimental functoins
    DeleteModal
  })

