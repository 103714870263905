/** 
 *  Locationsstore.js -- datastsructure for the locations object.
 */

  const LocationsStore = {
     id : true,
     name : "",
     description : "",
     long :0,
     lat: 0,
     published: true,
     pictureURL: ""
 }

export default LocationsStore