//@ts-check
/**
 * User
 */

import {store} from 'hybrids'


/**
* User controls the user session
*/ 
export const User = {
    email : "", 
    uid :  "", 
    isAnonymous : 1, 
    [store.connect]: {
        get: (id) => {
            return {
                email :  sessionStorage.getItem('alleyoop_email') || "",
                isAnonymous :  sessionStorage.getItem('alleyoop_anonymous') || 1,    
                uid :  sessionStorage.getItem('alleyoop_uid') || ""
            }
        },
        set: (id,values,keys) => {
            if ('email' in values) {
                sessionStorage.setItem('alleyoop_email', values.email)   
            }
            if ('isAnonymous' in values) {
                sessionStorage.setItem('alleyoop_anonymous', values.isAnonymous)
            }
            if ('uid' in values) {
                sessionStorage.setItem('alleyoop_uid', values.uid)
            }
            return values
        }
    }
}



export default User
