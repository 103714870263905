import { define, html, store } from "hybrids";
import firebase from "firebase/app";
import "firebase/analytics";
import "firebase/database";
import * as firebaseui from "firebaseui";
import User from "../user.js";

// login is called when the login-button is pressed to show the firebase interface.
function login(host, event) {
  firebase
    .auth()
    .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
    .then(function () {
      let ui =
        firebaseui.auth.AuthUI.getInstance() ||
        new firebaseui.auth.AuthUI(firebase.auth());
      host.ui = ui;
      host.ismodal = "";
      ui.start(
        event.target.getRootNode().querySelector("#firebaseui-auth-container"),
        {
          signInOptions: [firebase.auth.EmailAuthProvider.PROVIDER_ID],
          // Other config options...
          //signInFlow: 'popup',
          credentialHelper: firebaseui.auth.CredentialHelper.NONE,
          callbacks: {
            signInSuccessWithAuthResult: function (authResult, redirectUrl) {
              // User successfully signed in.
              // Return type determines whether we continue the redirect automatically
              // or whether we leave that to developer to handle.
              console.log("successfully logged in");
              firebase.analytics().logEvent("logged-in");
              host.ismodal = "hidden";
              return false;
            },
            signInFailure: function (error) {
              console.log("failed to log in");
              host.ismodal = "";
              return () => new Promise(console.log("callback"));
            },
          },
        }
      );
    });
}
/**
 *  Respond to any other click on the black tuff
 * @param {*} host
 * @param {*} event
 */
function closemodal(host, event) {
  host.ismodal = "hidden";
  host.ui.delete();
}

function logout(host, event) {
  firebase
    .auth()
    .signOut()
    .then(function () {
      firebase.analytics().logEvent("sign-out");
      // Sign-out successful.
    })
    .catch(function (error) {
      // An error happened.
    });
}

// registerIfNewUser checks the realtime database if the user is registered, if not
// it will add the user to the database.
function registerIfNewUser(user) {
  let userId = user.uid;
  var database = firebase.database();
  database
    .ref("/users/" + userId)
    .once("value")
    .then(function (snapshot) {
      if (snapshot.val() == null) {
        database.ref("/users/" + userId).set({
          email: user.email || "",
          name: user.displayName || "",
          profile_picture: user.photoURL || "",
        });
        firebase.analytics().logEvent("new-user");
      } else {
        // console.log("I found the user: ", userId )
      }
    });
}

const AdminApp = {
  hello: "hello world",
  app: null,
  user: null,
  userStore: () => store.get(User),
  ismodal: "hidden",
  auth_user: {
    connect: (host, key, invalidate) => {
      let firebaseConfig = {
        apiKey: "AIzaSyBGp3s92mBmBOiqtzxw9IzWTwBJpO4XUxw",
        authDomain: "alleyoop-59425.firebaseapp.com",
        databaseURL: "https://alleyoop-59425.firebaseio.com",
        projectId: "alleyoop-59425",
        storageBucket: "alleyoop-59425.appspot.com",
        messagingSenderId: "109068573460",
        appId: "1:109068573460:web:b5c24bd77cb78f3f910001",
        measurementId: "G-GVNP9TK8WM",
      };
      // Initialize Firebase
      if (host.app == null) {
        host.app = firebase.initializeApp(firebaseConfig);
        host.app.analytics();
        console.log("initializing app");
        firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
        firebase.auth().onAuthStateChanged(function (user) {
          if (user) {
            // User is signed in.
            //console.log("user signed in", user)
            host.user = user;
            if (user.isAnonymous) {
              console.log("the user is anonymous");
            } else {
              console.log("the user is: ", user.email);
              registerIfNewUser(user);
            }
            let new_values = {
              isAnonymous: user.isAnonymous ? 1 : 0,
              uid: user.uid,
              email: user.email,
            };
            store.set(User, new_values);
          } else {
            // Make an anonymous user-id so that they can interact with the site.
            // need to deal with the merging differently.
            // https://firebase.google.com/docs/auth/web/anonymous-auth
            firebase
              .auth()
              .signInAnonymously()
              .catch(function (error) {
                // Handle Errors here.
                var errorCode = error.code;
                var errorMessage = error.message;
                // ...
              });
          }
          return false;
        });
      }

      console.log("connected");
    },
  },
  render: ({ userStore, ismodal }) => html`
        <link rel="stylesheet" href="/mystyles.css"> 
        ${
          store.ready(userStore) &&
          userStore.isAnonymous == 1 &&
          html`
            <link
              type="text/css"
              rel="stylesheet"
              href="https://cdn.firebase.com/libs/firebaseui/3.5.2/firebaseui.css"
            />

            <button
              tabindex="0"
              class="inline-flex items-center bg-gray-100 shadow py-1 px-3   hover:bg-gray-300 rounded text-base mt-4 md:mt-0"
              onclick="${login}"
            >
              Log in / Sign up
              <svg
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                class="w-4 h-4 ml-1"
                viewBox="0 0 24 24"
              >
                <path d="M5 12h14M12 5l7 7-7 7"></path>
              </svg>
            </button>

            <!-- login modal -->
            <div
              class=" antialiased bg-gray-200  text-gray-900 font-sans overflow-x-hidden ${ismodal}"
            >
              <div
                class="fixed z-50 inset-0 flex items-center justify-center  w-full h-full"
              >
                <div
                  class="bg-gray-900 opacity-50 w-full h-full fixed z-50 inset-0"
                  onclick=${closemodal}
                ></div>
                <div
                  class="bg-white rounded-lg max-w-md m-4 p-4 inset-x-0 bottom-0 z-50 mb-4 mx-4 "
                >
                  <div class="">
                    <div id="firebaseui-auth-container"></div>
                  </div>
                </div>
              </div>
            </div>
            <!-- login modal end-->
            <!--

        <div class=" antialiased bg-gray-200  text-gray-900 font-sans overflow-x-hidden ${ismodal}">
            <div class="relative px-4 min-h-screen md:flex md:items-center md:justify-center">
                <div class="bg-gray-900 opacity-50 w-full h-full fixed z-50 inset-0" onclick=${closemodal}>
                </div>
                <div class="bg-white rounded-lg md:max-w-md md:mx-auto p-4 fixed inset-x-0 bottom-0 z-50 mb-4 mx-4 md:relative">
                <div class="md:flex items-center">
                    <div id="firebaseui-auth-container"> </div>
                </div>
            </div>
        </div>



<div class="modal ${ismodal}">
¸           <div class="modal-background"></div>
            <div class="modal-content">
                <div id="firebaseui-auth-container"> </div>
            </div> 
            <button class="modal-close is-large" onclick="${closemodal}" aria-label="close"></button> -->
          `
        }
        ${
          store.ready(userStore) &&
          userStore.isAnonymous == 0 &&
          html`
            <button
              tabindex="0"
              class="inline-flex items-center bg-gray-100 shadow py-1 px-3   hover:bg-gray-300 rounded text-base mt-4 md:mt-0"
              onclick="${logout}"
            >
              Log out
              <svg
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                class="w-4 h-4 ml-1"
                viewBox="0 0 24 24"
              >
                <path d="M5 12h14M12 5l7 7-7 7"></path>
              </svg>
            </button>
          `
        }
        </div>
        <slot></slot>
    `,
  auth_element: ({ render }) => {
    const target = render();
    return target.querySelector("#firebaseui-auth-container");
  },
};

export default AdminApp;

/*
 */
