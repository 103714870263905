import {store,html} from 'hybrids'

import User from '../user.js'

const IfAdmin = {
    warn: "",
    userStore: () => store.get(User),
    render : ({userStore,warn}) => html`
    ${store.ready(userStore) && (userStore.isAnonymous==0) && html`<slot></slot>`} 
    ${store.ready(userStore) && (userStore.isAnonymous==1) && warn=="1" && html`
  <link rel="stylesheet" href="/mystyles.css"> 
  <article class="bg-orange-300 border-t border-b border-grey-400 text-grey-700 px-4 py-3" role="alert">
  <div class="flex">    
    <div class="py-1"><svg class="fill-current h-6 w-6 text-black-500 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/></svg></div>
    <div>
      <p class="font-bold">No access to this page ... yet</p>
      <p class="text-sm">You currently don´t have access to this page.
       Please log in.
      </p>
    </div>
  </div>    
  </article>
    `} 
       
        
    `
}

export default IfAdmin
