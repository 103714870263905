import { html, store, parent } from "hybrids";
import firebase from "firebase/app";
import "firebase/analytics";
import "firebase/database";
import LocationsStore from "/src/locations-store.js";

// Prepopulate the list

store.set(LocationsStore, {
  name: "Oslo 1",
  description: "Et bra sted",
  lat: 59.911491,
  long: 10.757933,
  published: true,
});
store.set(LocationsStore, {
  name: "Oslo 2",
  description: "Et bra sted",
  lat: 59.911491,
  long: 10.755933,
  published: true,
});
store
  .set(LocationsStore, {
    name: "Oslo 3x",
    description: "Et bra sted",
    lat: 59.911491,
    long: 10.765933,
    published: true,
  })
  .then((loc) => {
    console.log("Created ", loc.id);
    let newo = store.get(LocationsStore, loc.id);
    console.log("Retrieved", newo.id);
  });

const SelectedState = {
  selected: false,
  selectedId: "",
};

export const LocationsAdmin = {
  selectedID: "",
  render: () => html`<slot></slot>`,
};

/**
 * kbdSelect handles enter and space for selecting a row using the keyboard.
 *
 * @param {*} host contains the LocationList object.
 * @param {*} event is the javascript event with reference ot the element.
 */
function kbdSelect(host, event) {
  switch (event.key) {
    case "Enter":
    case " ":
      let id = event.currentTarget.getAttribute("id");
      store.set(SelectedState, {
        selected: true,
        selectedId: id,
      });
      host.admin.selectedID = id;
      break;
  }
}

function clickSelect(host, event) {
  let id = event.currentTarget.getAttribute("id");
  store.set(SelectedState, {
    selected: true,
    selectedId: id,
  });
  host.admin.selectedID = id;
}

function deleteLocation(host, event) {
  let loc = store.get(LocationsStore, host.admin.selectedID);
  store.set(loc, null);
  host.admin.selectedID = null;
  store.set(SelectedState, {
    selected: false,
  });
}

function showModalDelete(host, event) {
  // check first if somehing has been selected.
  if (store.get(SelectedState).selected) {
    let el = event.target.parentElement.querySelector("#location-delete-modal");
    el.showModal = true;
  }
}

function addNewLocation(host, event) {
  store
    .set(LocationsStore, {
      name: "name",
      description: "No desciption",
      lat: 0,
      long: 0,
      published: false,
    })
    .then((o) => {
      host.admin.selectedID = o.id;
      store.set(SelectedState, {
        selected: true,
        selectedId: o.id,
      });
    });
}

/**
 * Render rows in the locationlst
 */
const renderTableRow = (locationList, admin, kbdSelect) => {
  return html`
    ${store.ready(locationList) &&
    locationList.map((loc) => {
      return html`
        <tr
          tabindex="0"
          id="${loc.id}"
          onclick="${clickSelect}"
          class="${loc.id == admin.selectedID &&
          "bg-blue-300"} hover:bg-gray-400"
          onkeypress="${kbdSelect}"
        >
          <td
            class="max-w-xs overflow-hidden text-gray-600 whitespace-no-wrap align-top"
          >
            ${loc.name}
          </td>
          <td
            class="max-w-xs overflow-hidden text-gray-600 whitespace-no-wrap align-top"
          >
            Oslo
          </td>
          <td
            class="max-w-xs overflow-hidden text-gray-600 whitespace-no-wrap align-top"
          >
            ${loc.long}
          </td>
          <td
            class="max-w-xs overflow-hidden text-gray-600 whitespace-no-wrap align-top"
          >
            ${loc.lat}
          </td>
        </tr>
      `;
    })}
  `;
};

/**
 * LOCATOION LIST
 */
export const LocationList = {
  locationList: store([LocationsStore]),
  admin: parent(LocationsAdmin),
  render: ({ locationList, admin }) => html`
    <link rel="stylesheet" href="/mystyles.css" />
    <!-- first pane List of locations-->
    <div class="flex flex-col justify-between p-2 ml-1 text-grey-500  ">
      <div class="justify-start">
        <h2 class="text-xl text-gray-500">Locations</h2>
        <div class="max-h-full scrolling-auto">
          <table>
            <thead>
              <tr>
                <td class="pr-4 mb-2 font-bold text-gray-500">Name</td>
                <td class="pr-4 mb-2 font-bold text-gray-500">City</td>
                <td class="pr-4 mb-2 font-bold text-gray-500">Longitude</td>
                <td class="pr-4 mb-2 font-bold text-gray-500">Latitude</td>
              </tr>
            </thead>
            <tbody class="bg-white">
              ${renderTableRow(locationList, admin, kbdSelect)}
            </tbody>
          </table>
        </div>
      </div>
      <!-- control pane -->
      <div class="flex">
        <button
          onclick="${addNewLocation}"
          class="pt-2 pb-2 pl-4 pr-4 m-1 text-white bg-blue-600 rounded shadow hover:bg-blue-800"
        >
          New location
        </button>
        <button
          onclick="${showModalDelete}"
          class="pt-2 pb-2 pl-4 pr-4 m-1 mr-1 text-white bg-red-600 rounded shadow hover:bg-red-800"
        >
          Delete location
        </button>
        <delete-modal
          id="location-delete-modal"
          onok="${deleteLocation}"
          ok="Ja"
          cancel="avbryt"
          heading="Slett lokasjon?"
        >
          Hvis du trykker ja, vil dataene om dette stedet slettes permanent.
        </delete-modal>
      </div>
    </div>
    <!-- end -->
  `,
};



function updateLongitude(host,target) {
  store.set(LocationsStore,{
    id: host.loc.id
  })
}

export const LocationShow = {
  state: store(SelectedState),
  selectedcsss: ({ state }) => (state.selected ? "visible" : "invisible"),
  loc: ({ state }) =>
    state.selected ? store.get(LocationsStore, state.selectedId) : {},
  render: ({ selectedcsss, loc }) => html`
<link rel="stylesheet" href="/mystyles.css" />
<!-- second pane for editing the location -->
<div class="flex flex-col justify-between w-full  p-2 mr-1 border-gray-400 ${selectedcsss}">
  <div class="justify-start">
    <h2 class="text-xl text-gray-500">Location details </h2>
    <form class="w-full mb-0 w-3xl">
      <div class="justify-start">
        <div>
          <!-- Name field -->
          <div class="mb-6 md:flex ">
            <div class="md:w-1/3">
              <label class="block pr-4 mb-1 font-bold text-gray-500 md:text-right md:mb-0" for="location-name">
                Name
              </label>
            </div>
            <div class="md:w-2/3">
              <input
                class="w-full px-4 py-2 leading-tight text-gray-500 bg-gray-200 border-2 border-gray-200 rounded appearance-none focus:outline-none focus:bg-white focus:border-purple-500"
                id="location-name" type="text" value="${
                  loc.name
                }" placeholder="Name of location">
            </div>
          </div>

          <!-- Longitude -->
          <div class="mb-6 md:flex ">
            <div class="md:w-1/3">
              <label class="block pr-4 mb-1 font-bold text-gray-500 md:text-right md:mb-0"
                for="longitude">
                Longitude
              </label>
            </div>
            <div class="md:w-2/3">
              <input
                class="w-full px-4 py-2 leading-tight text-gray-700 bg-gray-200 border-2 border-gray-200 rounded appearance-none focus:outline-none focus:bg-white focus:border-purple-500"
                id="longitude" type="text" value="${loc.long}" onchange="${updateLongitude}">
            </div>
          </div>

          <!-- Latitude -->
          <div class="mb-6 md:flex">
            <div class="md:w-1/3">
              <label class="block pr-4 mb-1 font-bold text-gray-500 md:text-right md:mb-0"
                for="latitude">
                Latitude
              </label>
            </div>
            <div class="md:w-2/3">
              <input
                class="w-full px-4 py-2 leading-tight text-gray-700 bg-gray-200 border-2 border-gray-200 rounded appearance-none focus:outline-none focus:bg-white focus:border-purple-500"
                id="latitude" type="text" value="${loc.lat}">
            </div>
          </div>

          <!-- Description -->
          <div class="mb-6 md:flex ">
            <div class="md:w-1/3">
              <label class="block pr-4 mb-1 font-bold text-gray-500 md:text-right md:mb-0 "
                for="description">
                Description
              </label>
            </div>
            <div class="md:w-2/3">
              <textarea rows="5"
                class="w-full px-4 py-2 leading-tight text-gray-700 bg-gray-200 border-2 border-gray-200 rounded appearance-none focus:outline-none focus:bg-white focus:border-purple-500"
                id="description" type="text" value="${
                  loc.description
                }"></textarea>
            </div>
          </div>

          <!-- Publisert-->
          <div class="mb-6 md:flex md:items-center">
            <div class="md:w-1/3"></div>
            <label class="block font-bold text-gray-500 md:w-2/3">
              <input class="mr-2 leading-tight" type="checkbox" ${
                loc.published ? "checked" : ""
              }>
              <span class="text-sm">
                Publisert
              </span>
            </label>
          </div>
        </div>

        <!-- control pane -->
        <div>
          <div class="flex justify-end ">
            <button
              class="pt-2 pb-2 pl-4 pr-4 m-1 mr-1 overflow-hidden text-white whitespace-no-wrap bg-blue-600 rounded shadow hover:bg-blue-800 hover: hover:scale-125">Save
              changes</button>
            <button
              class="pt-2 pb-2 pl-4 pr-4 m-1 mr-1 overflow-hidden text-white whitespace-no-wrap bg-gray-600 rounded shadow hover:bg-gray-500">Cancel
              edits</button>
          </div>
        </div>
    </form>
  </div>
</div>

  `,
};
